import React, { useEffect, useState } from 'react';
import { FilterOption, ModalBody, ModalContent, ModalHeader, ModalOverlay } from './FoldersStyles';
import { CloseButton } from '../CloseButton';
import { Button } from '@mui/material';

type Props = {
    onClose: () => void;
    filterTypes: string[];
    setFilterFunction: any;
    folderCategory: string;
    //onFilterTypeChange: (newType: string[]) => void;
}

const FilterModal = ({ onClose, filterTypes, setFilterFunction, folderCategory }: Props) => {

    const [selectedFilterTypes, setSelectedFilterTypes] = useState<string[]>([]);
   // setSelectedFilterTypes(filterTypes);
    const handleFilterChange = (filterType: string) => {
      
        if (selectedFilterTypes.includes(filterType)) {
            setSelectedFilterTypes(selectedFilterTypes.filter((type) => type !== filterType));
        }
        else if (filterType == "Reset") {
            setSelectedFilterTypes(["Reset"]);

        }
        else {
            setSelectedFilterTypes([...selectedFilterTypes, filterType]);
        }
        
    };
    const applyFilters = () => {
            setSelectedFilterTypes(selectedFilterTypes);
              };

    useEffect(() => {
        setFilterFunction(selectedFilterTypes);
    }, [selectedFilterTypes]);

    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Filter</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody>
                    <FilterOption style={selectedFilterTypes.includes("Alpha") ? { border: '2px #253872 solid', boxShadow:'1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Alpha') }}>Alphabetical Order</FilterOption>
                    <FilterOption style={selectedFilterTypes.includes("StudentId") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('StudentId') }}>Student ID</FilterOption>            

                    {folderCategory == "Academic" && <FilterOption style={selectedFilterTypes.includes("AllStudents") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('AllStudents') }}>All Students</FilterOption>}
                    {folderCategory == "Academic" &&     <FilterOption style={selectedFilterTypes.includes("ActiveStudents") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('ActiveStudents') }}>Current Students</FilterOption>}
                    {folderCategory == "Academic" &&   <FilterOption style={selectedFilterTypes.includes("InactiveStudents") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('InactiveStudents') }}>Past Students</FilterOption>}

                    <div>
                        <Button onClick={async () => {
                            applyFilters();
                            onClose()
                            }}
                            variant="contained" style={{ backgroundColor: '#253872', marginTop: '45px' }}>Apply
                        </Button>
                        <Button onClick={ () => {
                            handleFilterChange('Reset');
                           // applyFilters();
                           // onClose();
                        }}
                            variant="outlined" style={{ color: '#253872', borderColor:'#253872',marginLeft: '5px', marginTop:'45px' }}>Clear
                        </Button>
                    </div>
                </ModalBody>
               
            </ModalContent>
        </ModalOverlay>
    );
};

export default FilterModal;
