import styled from "@emotion/styled";


export const FeedbackDate = styled.div`
color: var(--Black, #000);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;
