import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";
import { FaDownload } from "react-icons/fa6";
import axios from 'axios';
import { Form } from '../../globalTypes/objects';
import saveAs from 'file-saver';
import { useToken } from "../../useToken";
import DownloadIcon from '../Processing/DownloadIcon';
import { API_BASE_URL } from '../../urlConfig';

const OuterWrap = styled.div`
  border-radius: 5px;
  border: 2px solid #b5b5b8;
  padding: 40px 24px;
  width: 250px;
`;

const InnerStyles = styled.div`
 display:flex;
 padding-top: 10px;
 padding-bottom: 10px;
 align-items:center;
 cursor:pointer;
 border-bottom: 1px #8397F826 solid;
`


const ModalHeader = styled.div`
background-color:#8397F826;
font-weight:700;
color:#253872;
font-size:16px;
align-items:center;

`;



type Props = {
    projectId: number;
}

const DownloadDocuments = ({ projectId }: Props) => {
    const [documents, setDocuments] = useState<Form[]>([]);

   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const getDocuments = async () => {
        try {
            const localToken = await getToken();
            const url = API_BASE_URL;
            const { data } = await axios.get(`${API_BASE_URL}/api/Project/getProjectDocuments/${projectId}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setDocuments(data);
        } catch (error: any) {
            console.log(error.message);
            //setError(error.message || 'An unknown error occurred');
        }
    };

    const handleDocumentDownload = async (form: Form) => {
        try {
            const localToken = await getToken();
            //   const baseUrl = process.env.REACT_APP_API_BASE_URL;
            logDownloadDocument(form.id);
            const response = await axios.get(`${API_BASE_URL}/api/Document/DownloadProjectSchoolSubmittedDocument/${form.id}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });


            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            saveAs(blob, form.fileName);
        } catch (e) {
             console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };

    const logDownloadDocument = async (formId: number) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Logger/addDownloadSchoolSubmittedProjectLog/${formId}`, {}
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                console.log(error?.response?.data);

            })
    };



    useEffect(() => {
        getDocuments()
    }, []);

    return (
        <div>
            {
                documents.map(
                    (document) =>
                        <InnerStyles onClick={() => handleDocumentDownload(document)}>

                            <div style={{ width: '15%' }}> <DownloadIcon /></div>
                            <div style={{ width: '75%', paddingTop: '5px', paddingLeft: '5px', fontSize: '13px', textAlign: 'left' }}>{document.fileName}</div>
                        </InnerStyles>
                )
            }
        </div>
       

        //<OuterWrap>
            
        //</OuterWrap>
    );
};

export default DownloadDocuments;
