import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { TimelineItem } from '../../globalTypes/objects';
import { useToken } from "../../useToken";
import SelectedSchoolContext from '../SelectedSchoolContext';
import { EndOfMonthHeader, MonthColumn, MonthHeader, MonthItem } from './YearlyOverviewStyles';
import NextIcon from '../NextIcon';
import PreviousIcon from '../PreviousIcon';
import moment from 'moment';
import DateIcon from './DateIcon';
import { Box, Divider } from '@mui/material';
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';



const YearlyOverview = () => {
    const [timeline, setTimeline] = useState<TimelineItem[]>();
    const [startIndex, setStartIndex] = useState<number>();
    const [endIndex, setEndIndex] = useState<number>();
    moment.locale('en-us');
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
   // }
  //  const { isLoadingSchools, selectedSchoolId, selectedSchoolName } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;
   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const getTimeline = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Timeline/getTimelineDetailed/${selectedSchoolId}`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setTimeline(data);
            if (window.matchMedia("(max-width: 1500px)").matches) {
                setStartIndex(0);
                setEndIndex(1);
            }
            else {
                setStartIndex(0);
                setEndIndex(3);
            }
        } catch (error: any) {
            //todo
        }
    };

    useEffect(() => {
        getTimeline().catch(error => {
            console.error('Error getting timeline:', error);
        });
        function handleResize() {
            getTimeline().catch(error => {
                console.error('Error getting timeline:', error);
            });
        }

        // Attach the event listener to the window object
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    function sortByMonth(a: string, b: string): number {
        return months.indexOf(a) - months.indexOf(b);
    }

    const groupedTimeline = timeline?.reduce((acc, timelineItem) => {
        const month = timelineItem.deadlineMonth;

        if (!acc[month]) {
            acc[month] = [];
        }

        acc[month].push(timelineItem);

        return acc;
    }, {} as { [key: string]: TimelineItem[] });

    const sortedMonths = groupedTimeline ? Object.keys(groupedTimeline).sort(sortByMonth) : null;
    const currMonth = new Date().getMonth();
    const sortedMonthsStartThisMonth = sortedMonths?.slice(currMonth).concat(sortedMonths?.slice(0, currMonth));

    const handleNextClick = () => {
        const nextStartIndex = startIndex != null ? startIndex + 1 : 0;
        const nextEndIndex = endIndex != null ? endIndex + 1 : 3;

        const maxStart = (window.matchMedia("(max-width: 1500px)").matches) ? 11 : 9;

        setStartIndex(nextStartIndex > maxStart ? maxStart : nextStartIndex),
            setEndIndex(nextEndIndex > 12 ? 12 : nextEndIndex)

    };
    const handlePreviousClick = () => {
        const nextStartIndex = startIndex != null ? startIndex - 1 : 0;
        const nextEndIndex = endIndex != null ? endIndex - 1 : 3;

        const maxEnd = (window.matchMedia("(max-width: 1500px)").matches) ? 1 : 3;

        setStartIndex(nextStartIndex < 0 ? 0 : nextStartIndex),
            setEndIndex(nextEndIndex < maxEnd ? maxEnd : nextEndIndex)

    };
    //const locales = ['en-us', 'en-gb', 'zh-cn', 'de'];
    //type LocaleKey = (typeof locales)[number];
    //const [locale, setLocale] = React.useState<LocaleKey>('en-us');

    //if (moment.locale() !== locale) {
    //    moment.locale(locale);
    //}

    const formatDate = (dateString: string) => {
        const date = moment(dateString);
        const formatedDate = date.format("MMM DD");
        /*const date = new Date(dateString);*/  
        return formatedDate;

    };

    const getYear = (month: string) => {
        const monthRecords = timeline?.filter(x => x.deadlineMonth == month);
        let deadlineDate = '';
        monthRecords?.map(a => deadlineDate = a.deadlineDate);

        const date = moment(deadlineDate);
        const year = date.format("YYYY");
        return year;

    };

    return (
        <>

            <div className="pageHeader">Yearly Overview</div>

            <div>
                <div style={{ display: 'flex', paddingLeft: '63%' }}> <PreviousIcon onClick={() => handlePreviousClick()} /><NextIcon showNext={true}  onClick={() => handleNextClick()} /></div>


                {timeline && sortedMonthsStartThisMonth && groupedTimeline &&
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {sortedMonthsStartThisMonth.slice(startIndex, endIndex).map((month: string) => (
                            <div key={month} style={{ padding: 25, paddingLeft: 0 }}>
                                <MonthColumn key={self.crypto.randomUUID()} >
                                    <MonthHeader key={self.crypto.randomUUID()}>{month === 'NULL' ? 'No month specified' : month} {getYear(month)}</MonthHeader>
                                    <div key={self.crypto.randomUUID()}>
                                        {groupedTimeline[month].filter(x => x.deadlineType == 'General').map((timelineItem) => (
                                     
                                            <Box key={self.crypto.randomUUID()}><Box key={self.crypto.randomUUID()}  sx={{ display: 'flex', padding: '15px' }}>
                                                <DateIcon key={self.crypto.randomUUID()} dateText={formatDate(timelineItem.deadlineDate)} />
                                                <MonthItem key={self.crypto.randomUUID()}> {timelineItem.taskDescription}</MonthItem>
                                            </Box>
                                                <Box key={self.crypto.randomUUID()} sx={{ paddingLeft: '5%', paddingRight: '5%' }}> <Divider key={self.crypto.randomUUID()}></Divider></Box>
                                            </Box>
                                        ))}
                                    </div>
                                    <div key={self.crypto.randomUUID()}>
                                        {groupedTimeline[month].filter(x => x.deadlineType == 'Beginning of the new year').length > 0 && <EndOfMonthHeader key={self.crypto.randomUUID()}>Beginning of the New Year</EndOfMonthHeader>}
                                        {groupedTimeline[month].filter(x => x.deadlineType == 'Beginning of the new year').map((timelineItem) => (
                                            <Box key={self.crypto.randomUUID()}><Box key={self.crypto.randomUUID()} sx={{ display: 'flex', padding: '15px' }}>
                                                <DateIcon key={self.crypto.randomUUID()} dateText={formatDate(timelineItem.deadlineDate)} />
                                                <MonthItem key={self.crypto.randomUUID()}> {timelineItem.taskDescription}</MonthItem>
                                            </Box>
                                                <Box key={self.crypto.randomUUID()} sx={{ paddingLeft: '5%', paddingRight: '5%' }}> <Divider key={self.crypto.randomUUID()}></Divider></Box>
                                            </Box>
                                        ))}
                                    </div>
                                    <div key={self.crypto.randomUUID()}>
{/*                                        {groupedTimeline[month].filter(x => x.deadlineType == 'Beginning of any semester or module').length > 0 && <EndOfMonthHeader>Beginning of Semester or Module</EndOfMonthHeader>}*/}
                                        {groupedTimeline[month].filter(x => x.deadlineType == 'Beginning of any semester').map((timelineItem,index) => (
                                            <div key={self.crypto.randomUUID()}>   {index == 0 && <EndOfMonthHeader key={self.crypto.randomUUID()}>Beginning of {timelineItem.semesterModuleType}</EndOfMonthHeader>}
                                                <Box key={self.crypto.randomUUID()}><Box key={self.crypto.randomUUID()} sx={{ display: 'flex', padding: '15px' }}>
                                                    <DateIcon key={self.crypto.randomUUID()} dateText={formatDate(timelineItem.deadlineDate)} />
                                                    <MonthItem key={self.crypto.randomUUID()}> {timelineItem.taskDescription}</MonthItem>
                                            </Box>
                                                    <Box key={self.crypto.randomUUID()} sx={{ paddingLeft: '5%', paddingRight: '5%' }}> <Divider key={self.crypto.randomUUID()}></Divider></Box>
                                            </Box></div>
                                        ))}
                                    </div>

                                    <div key={self.crypto.randomUUID()}>
                                       {/* {groupedTimeline[month].filter(x => x.deadlineType == 'Months a semester or module ends').length > 0 && <EndOfMonthHeader> End of { } Semester or Module</EndOfMonthHeader>}*/}
                                        {groupedTimeline[month].filter(x => x.deadlineType == 'Months a semester ends').map((timelineItem, index) => (
                                            <div key={self.crypto.randomUUID()}>
                                                {index == 0 && <EndOfMonthHeader key={self.crypto.randomUUID()}>End of {timelineItem.semesterModuleType}</EndOfMonthHeader>}
                                                <Box key={self.crypto.randomUUID()}><Box key={self.crypto.randomUUID()} sx={{ display: 'flex', padding: '15px' }}>
                                                    <DateIcon key={self.crypto.randomUUID()} dateText={formatDate(timelineItem.deadlineDate)} />
                                                    <MonthItem key={self.crypto.randomUUID()}> {timelineItem.taskDescription}</MonthItem>
                                                </Box>
                                                    <Box key={self.crypto.randomUUID()} sx={{ paddingLeft: '5%', paddingRight: '5%' }}> <Divider key={self.crypto.randomUUID()}></Divider></Box>
                                                </Box>
                                            </div>
                                        ))}
                                    </div> <div key={self.crypto.randomUUID()}>
                                        <EndOfMonthHeader key={self.crypto.randomUUID()}>Every Month</EndOfMonthHeader>
                                        {groupedTimeline[month].filter(x => x.deadlineType == 'Every month').map((timelineItem) => (
                                            <Box key={self.crypto.randomUUID()}><Box key={self.crypto.randomUUID()} sx={{ display: 'flex', padding: '15px' }}>
                                                <DateIcon key={self.crypto.randomUUID()} dateText={formatDate(timelineItem.deadlineDate)} />
                                                <MonthItem key={self.crypto.randomUUID()}> {timelineItem.taskDescription}</MonthItem>
                                            </Box>
                                                <Box key={self.crypto.randomUUID()} sx={{ paddingLeft: '5%', paddingRight: '5%' }}> <Divider key={self.crypto.randomUUID()}></Divider></Box>
                                            </Box>
                                        ))}
                                    </div>



                                </MonthColumn>
                            </div>
                        ))}
                    </div>

                }



            </div>
        </>
    );
};

export default YearlyOverview;