import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Need } from '../../globalTypes/objects';
import SubmitButton from '../SubmitButton';
import {useAuth0} from "@auth0/auth0-react";
import {useToken} from "../../useToken";
import { API_BASE_URL } from '../../urlConfig';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const Input = styled.input`
  padding: 10px;
  font-size: 18px;
  width: 355px;
  box-sizing: border-box;
`;

const AddFeedback = () => {
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [invalidNote, setInvalidNote] = useState(false);
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState('');


    //const baseUrl = process.env.REACT_APP_API_BASE_URL;


    const handleNoteChange = (event: { target: { value: any; }; }) => {
        const inputValue = event.target.value;
        setNote(inputValue);

        if (inputValue.length >= 255) {
            setInvalidNote(true);
        } else {
            setInvalidNote(false);
        }
    };
    const getToken = useToken();
   
    const handleAddFeedback = async () => {
      
            const localToken = await getToken();
            setLoading(true);
        axios.post(`${API_BASE_URL}/api/feedback/AddFeedback`, { FeedbackText: note}
               , {

                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                })
                .then(() => {
                    setSuccess(true);
                    setLoading(false);
                })
                .catch(error => {
                    setResponse(error?.response?.data);
                    setLoading(false);
                })
        
    }


    return (
        <div style={{ width: '100%' }}>

            {success ?
                (<div style={{ color: '#5EB234' }}>Your feedback was successfully submitted!</div>)
                :
                (<><div style={{ textAlign: 'left' }}>
                    
                </div>

                    <div>
                        Any feedback or suggestions to make the portal better?
                    </div>
                    <div style={{ paddingTop: '50px' }}>
                    <TextField
                            id="outlined-multiline-flexible"
                            label="Add Feedback Here"
                            multiline rows={3 }
                             onChange={handleNoteChange} fullWidth 
                        />
                    </div></>)}
            

            {invalidNote && (
                <div>
                    <p style={{ color: 'red' }}>Feedback is too long</p>
                </div>
            )}

            {response && (
                <div>
                    <p style={response == "Success" ? { color: 'green' } : { color: 'red', width: '600px' }}>Response: {response}</p>
                </div>
            )}

            {note && !success && (
                <div style={{
                   height: '60px', paddingTop: '15px', textAlign: 'center'
                }}>
               
                   

                    <Button onClick={handleAddFeedback} variant="contained" disabled={loading || invalidNote} style={{  backgroundColor: '#253872' }}>Submit
                    </Button> 
                </div>

               
            )}
            {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft: '25%' }} />}
        </div>
    );
};

export default AddFeedback;